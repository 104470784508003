

<template>
    <div class="blank-header-container">
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  
  export default Vue.extend({
    name: 'BlankHeaderComponent',
    data() {
      return {
      }
    },
    beforeMount() {
      
    },
    computed: {
      
    },
    methods: {
      
    }
  })
  </script>
  