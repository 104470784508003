

<template>
  <div class="d-flex">
    <div class="d-flex select-button">
     <div ref="menuButton" class="customHeaderMenuButton" @click="onSelectToggle($event)">
       <font-awesome-icon class="selection-icon" :icon="selectedAll ? ['far', 'square-check'] : selectedSome ? ['far', 'square-minus'] : ['far','square']"/>
     </div>
   </div>
   <!-- <span class="ml-1 display-name">{{ params.displayName }}</span> -->
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SelectorRangeSelectionHeaderComponent',
  data() {
    return {
      selectedAll: false,
      selectedSome: false,      
    }
  },
  beforeMount() {
    this.prepareData()
  },
  computed: {

  },
  methods: {
    prepareData() {
      this.$nextTick(() => {
        const selectionStatus = this.getSelectionStatus()
        this.selectedAll = selectionStatus == 'all'
        this.selectedSome = selectionStatus == 'some'
      })
    },
    refresh(params) {
      this.params = params
      this.prepareData()
    },
    getSelectionStatus() {
      const api = this.params.api
      const cellRanges = api.getCellRanges()
      if (cellRanges.length == 0) {
        return 'none'
      }
      const selectedRows = new Set()
      for (const range of cellRanges) {
        let startIdx = range.startRow.rowIndex
        let endIdx = range.endRow.rowIndex
        if (startIdx > endIdx) {
          startIdx = range.endRow.rowIndex
          endIdx = range.startRow.rowIndex
        }
        
        for (let i = startIdx; i <= endIdx; i++) {
          selectedRows.add(i)
        }
      }

      const pageSize = api.paginationGetRowCount()
      const rowCount = api.getDisplayedRowCount()
      const notPaged = pageSize === 0 && rowCount !== 0;
      const currentPageIdx =  api.paginationGetCurrentPage()
      const remainingRowCount = (pageSize * currentPageIdx) - api.paginationGetRowCount()
      const totalCount = notPaged ? rowCount : remainingRowCount > 0? remainingRowCount: pageSize

      if (selectedRows.size == 0) {
        return 'none'
      } else if ( selectedRows.size == totalCount) {
        return 'all'
      } else {
        return 'some'
      }
    },
    
    onSelectToggle(event) {
      const api = this.params.api
      if (this.selectedAll) {
        api.deselectAllFiltered()
        api.clearRangeSelection()
      }
      else {
        const pageSize = api.paginationGetRowCount()
        const rowCount = api.getDisplayedRowCount()
        const notPaged = pageSize === 0 && rowCount !== 0;
        const currentPageIdx =  api.paginationGetCurrentPage()
        const remainingRowCount = (pageSize * currentPageIdx) - api.paginationGetRowCount()
        const startIdx = currentPageIdx
        const length = notPaged ? rowCount - 1 : (remainingRowCount > 0? remainingRowCount: pageSize) - 1
        const endIdx = startIdx + length
        
        if (endIdx > -1) {
          const range = {
            rowStartIndex: startIdx,
            rowEndIndex: endIdx,
            columns: [this.params.nameColumn ? this.params.nameColumn : 'uuId']
          }
          api.clearRangeSelection()
          api.addCellRange(range)

          //Make sure there is a focused cell in the grid.
          //Without focused cell, cell navigation, delete key interaction will not work.
          api.setFocusedCell(range.rowStartIndex, range.columns[0])
          //Force focus on focused cell, so that the copy & paste keyboard interaction works.
          event.target.closest('.ag-root-wrapper-body').querySelector('.ag-cell-focus').focus()
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.selection-icon {
  font-size: 18px;
  margin-top: 1px;
  color: var(--gray-500);
}

.select-menu {
  padding: 0 2px;
}

.select-button {
  padding: 3px;
  width: fit-content;
  border: 1px solid transparent;
}

.display-name {
  margin-top: 5px;
}

.select-button:focus,
.select-button:active,
.select-button:hover {
  background: var(--backdrop);
  border-radius: 3px;
  border: 1px solid var(--border-dark);
}
</style>
