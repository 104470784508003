var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.avatarUrl
    ? _c(
        "div",
        {
          staticClass: "avatar-container",
          style: { marginLeft: _vm.marginLeft },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: { width: "120", height: 25, src: _vm.avatarUrl },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }