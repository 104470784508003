var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("LocationBadgeGroupModal", {
    attrs: {
      show: _vm.modalShow,
      locationList: _vm.locationList,
      multiple: _vm.multiple,
    },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.locationSelectorOk,
      cancel: _vm.locationSelectorCancel,
      companyDeleted: _vm.locationSelectorChanged,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }