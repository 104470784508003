<template>
    <div class="avatar-container" :style="{ marginLeft: marginLeft }" v-if="avatarUrl">
      <img class="image" width="120" :height="25" :src="avatarUrl"/>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import { setReadOnlyIfNotEditable } from './util.js';
  
  export default Vue.extend({
    name: 'AvatarCellRenderer',
    data() {
      return {
        
      }
    },
    beforeMount() {
      setReadOnlyIfNotEditable(this.params);
    },
    computed: {
      avatarUrl() {
        return this.params && this.params.value 
          && this.params.value !== "00000000-0000-0000-0000-000000000000" 
          ? (this.params.value.startsWith('data:image/') 
            ? this.params.value 
            : `${process.env.BASE_URL}api/file/${this.params.value}`) 
          : null;
      },
      marginLeft() {
        return this.params?.nonAdmin == true? '0' : '-9px';
      },

    }
  });
  </script>
  
  <style lang="scss" scoped>
  .avatar-container {
    height: 100%;
    // margin-left: -9px;
    padding-top: 3px;
  }

  .image {
    object-fit: cover;
    width: 20px;
    height: 20px;
    border: 2px solid var(--banner-avatar-border);
    border-radius: 50%;
    display: inline-block;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 1;
    vertical-align: baseline;
  }
  </style>